import Typography from '@material-ui/core/Typography';
import React from 'react';

const Page: React.FC = () => {
  return (
    <Typography variant="h2">Not Found!</Typography>
  );
};

export default Page;
